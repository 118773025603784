export default {
  "LanguageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski (PL)"])},
  "logoFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo-sagrada-legacy_PL.png"])},
  "logoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagrada Legacy logo"])},
  "GetTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grę kupisz tutaj!"])},
  "ChooseOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jeden"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])},
  "emailAddressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witrażysto, podaj swój adres e-mail"])},
  "emailHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój adres e-mailowy nie zostanie udostępniony zewnętrznym podmiotom, ani dodany do żadnej bazy."])},
  "WaitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeszczę chwila..."])},
  "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nie, coś poszło nie tak. Spróbuj ponownie."])},
  "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś znów poszło nie tak! Skontaktuj się z naszym zespołem wsparcia."])},
  "WindowInstructions1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Jedyny w swoim rodzaju witraż pod nazwą \"", _interpolate(_list(0)), "\" należy do Ciebie!"])},
  "WindowInstructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Sprawdź</strong> swoją skrzynkę, witraż zaraz do Ciebie dotrze. Pamiętaj, żeby <strong>sprawdzić</strong> również folder ze spamem."])},
  "SendItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie"])},
  "ReturnToTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wracamy do gry"])},
  "EnvelopeS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koperta S"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odblokowanie"])},
  "colorColumn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Column"])},
  "all6ColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ukończenie ", _interpolate(_list(0)), " kolumny!"])},
  "noColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie wybudowano żadnego piętra ", _interpolate(_list(0)), " kolumny."])},
  "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czerwonej"])},
  "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żółtej"])},
  "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielonej"])},
  "Blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niebieskiej"])},
  "Purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fioletowej"])},
  "return": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witrażyści, kontynuujcie."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie macie czasem pracy do wykonania?"])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobieranie Twojego witraża..."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czymkolwiek jest pobieranie... Ja tu tylko tworzę witraże!"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Sprawdź</strong> folder Pobrane."])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Wydrukuj</strong> po jednej kopii dla każdego z graczy."])},
    "instructions3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Rozpocznijcie</strong> rozgrywkę zgodnie z zasadami przedstawionymi u góry strony."])}
  },
  "envelopeD": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Och, widzę, że nie mogliście sie oprzeć!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ale skoro już tu jesteście...  Jak wygląda Wasz postęp w budowie katedry?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Wybierzcie</strong> opcję, która najlepiej opisuje stan prac."])},
      "progressQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile kondygnacji katedry udało Wam się wybudować?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeszcze nawet nie zaczęliśmy!"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybudowaliśmy tylko część katedry."])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prace ukończone! (6 naklejek na każdej z 5 kolumn)"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co Wy tu jeszcze robicie? Macie przed sobą mnóstwo pracy! <br/>Wróćcie tu, gdy uda Wam się osiągnąć chociaż niewielki postęp."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Kontynuujcie</strong> prace nad budową katedry."])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeszcze nie ukończone?<br/>Czym Wy się w takim razie zajmujecie?!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na jakim etapie jesteście?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Uzupełnijcie</strong> informacje o liczbie naklejek na kolejnych kondygnacjach katedry."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przecież mówiliście, że jeszcze nie zakończyliście prac!"])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prace ukończone?!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaimponowaliście mi swoją cierpliwością!"])},
      "instructions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skoro ukończyliście budowę katedry, nadszedł czas na ", _interpolate(_named("odblokowanie")), " ", _interpolate(_named("linkToEnvelopeS"))])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadchodzi jeszcze więcej pracy!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam coś specjalnego dla tak rozkojarzonych witrażystów jak Wy... kolejny witraż do ukończenia!  Co? Oczywiście, że nie płacimy za nadgodziny!"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Wprowadźcie</strong> adres e-mail, na który mam Wam przesłać witraż."])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasz nowy witraż jest w drodze!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stworzyliśmy jedyny w swoim rodzaju witraż specjalnie dla Was, to powinno Wam pomóc wrócić na właściwe tory!"])}
    }
  },
  "envelopeS": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imponujące!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byłem pełen obaw, że już nigdy nie ukończycie budowy! Nie do wiary, że udało Wam się ukończyć nawet nowy ... cóż to? Nie dostaliście informacji o nowych planach? Cóż, są całkiem..."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Wybierzcie</strong> opcję, która najlepiej odzwierciedla Wasz apetyt na nowe wyzwania."])},
      "WhatDifficultyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na jaki poziom trudności jesteście gotowi?"])},
      "difficultyOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni - Niech poniesie nas wyobraźnia."])},
      "difficultyOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki - Sprawdźmy nasze umiejętności."])},
      "difficultyOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arcytrudny - Nic nas nie powstrzyma!"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niech Wam będzie."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" <em>Sądzę</em> , że zasłużyliście na małą przerwę. Możecie iść, chyba że..."])},
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niech już będzie..."])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No to dawaj!"])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór godny szacunku."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To powinno pomóc Wam w rozwinięciu umiejętności."])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że natrafiłem na legendę..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekonamy się, jak sobie z tym poradzicie."])}
    },
    "step2": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Wprowadź</strong> adres e-mail, na który mamy Wam przesłać nowy witraż. Miejmy nadzieję, że poziom trudności będzie dla Was odpowiedni."])}
    }
  }
}