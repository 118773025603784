<template>
  <div :class="getLocaleClassName()">
    <img :alt="$t('logoAltText')" :src="getLogoFileName()" class="logo-image">
    <br/>
    <router-view></router-view>  
    <footer>
        <LocaleChanger></LocaleChanger>
    </footer>
  </div>
</template>

<script>
  import LocaleChanger from './components/LocaleChanger.vue';
  
  export default {
    name: 'App',
    components: {
        LocaleChanger
    },

    methods: {
      getLogoFileName: function() {        
        return require('./assets/' + this.$t('logoFileName'));
      },
      getLocaleClassName: function() {        
        let extendedCharacterLocales = ['pl-PL'];

        let locale = this.$i18n.locale;
        return extendedCharacterLocales.includes(locale) ? 'extended-characters' : '';       
      }
    }
  }
</script>

<style>

@font-face {
  font-family: 'sa-serif-extra-bold';
  src: url('./assets/sa-serif-extra-bold.otf') format('opentype');
}
@font-face {
  font-family: 'sa-serif-medium';
  src: url('./assets/sa-serif-medium.otf') format('opentype');
}
@font-face {
  font-family: 'sa-serif-extra-bold-extended';
  src: url('./assets/DejaVuSerif-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'sa-serif-medium-extended';
  src: url('./assets/DejaVuSerif.ttf') format('truetype');
}

html {
  background: url(./assets/paper-background.jpg) no-repeat center center fixed;
  background-size: cover;  
}

body { 
  background-color: inherit;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

/* center the footer to the bottom of the screen */
footer {  
  height: 60px;  
  margin-top: 150px;  
}

#app {
  font-family: 'sa-serif-medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
#app .extended-characters {
  font-family: 'sa-serif-medium-extended';
}
h3, strong {
  font-family: 'sa-serif-extra-bold';
}
.extended-characters h3, .extended-characters strong {
  font-family: 'sa-serif-extra-bold-extended';
}
.logo-image {  
  margin-bottom: 10px;  
}
.btn-primary {
  background-color: #9bc47c;
  border-color: #617b4e;
}
.btn-primary:hover {
  background-color: #b0de8c;
  border-color: #9bc47c;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #ccc;
  border-color: #999;
}

form label {
  font-size: 1.2em;
  font-family: 'sa-serif-extra-bold';
  text-align: left;
}
.extended-characters form label {
  font-family: 'sa-serif-extra-bold-extended';
}
.form-group label {
  text-align: left;
}

.story {
  font-style: italic;
}

.instructions {
  display: block;
  background-color: #fff;
  border: 2px solid #f79c2d;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 10px 0;
  text-align: left;
}
.instructions li {
  margin-left: 20px;
}

.instructions a { 
  font-family: 'sa-serif-extra-bold';
  text-decoration: underline;
  color:  #9bc47c;
}
.extended-characters .instructions a {
  font-family: 'sa-serif-extra-bold-extended';
}
.instructions a:hover {
  color: #b0de8c;
}

</style>
